body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.btn-margin {
  margin: 7px 3px;
}

